import React from 'react'
import './TermsOfService.css'

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        <strong>Effective Date:</strong> 06/09/2024
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Chess Predict ("the Service") provided by Chess Predict ("we," "our," or "us"), you agree
        to comply with and be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, you may not
        use the Service.
      </p>

      <h2>2. Description of Service</h2>
      <p>
        Chess Predict allows users to upload screenshots of digital chess games to receive predictions on the best move
        and an analysis of that move. The Service is intended for educational and training purposes only.
      </p>

      <h2>3. Prohibited Uses</h2>
      <p>
        You expressly acknowledge and agree that Chess Predict is designed and intended for post-game analysis and 
        training purposes only. The use of Chess Predict during active chess games, tournaments, or any competitive 
        play is strictly prohibited and constitutes a violation of these Terms of Service. Specifically:
      </p>
      <ul>
        <li>You may not use the Service to assist in active chess games, whether online or in-person.</li>
        <li>You may not use the Service during chess tournaments, matches, or any form of competitive play.</li>
        <li>You may not use the Service to gain unfair advantages in rated games or competitions.</li>
        <li>You acknowledge that using the Service for cheating purposes may violate the terms of service of chess platforms, 
            tournament rules, and may be illegal in certain jurisdictions.</li>
      </ul>
      <p>
        By using Chess Predict, you assume full responsibility for compliance with all applicable chess platform rules, 
        tournament regulations, and local laws. Any violation of this section will result in immediate account termination.
      </p>

      <h2>4. Registration</h2>
      <p>
        To use the Service, you must register and create an account using your email address. You agree to provide
        accurate and complete information during registration and to update such information to keep it accurate and
        complete.
      </p>

      <h2>5. User Conduct</h2>
      <p>You agree not to:</p>
      <ul>
        <li>Use the Service for any illegal or unauthorized purpose.</li>
        <li>Upload any content that is unlawful, offensive, defamatory, or otherwise objectionable.</li>
        <li>Interfere with or disrupt the Service or servers or networks connected to the Service.</li>
      </ul>

      <h2>6. Intellectual Property</h2>
      <p>
        All content, features, and functionality of the Service, including but not limited to the prediction algorithm
        and analysis tools, are the exclusive property of Chess Predict and are protected by copyright, trademark, and
        other intellectual property laws.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        You expressly understand and agree that Chess Predict shall not be liable for any damages resulting from the misuse 
        of our Service, including but not limited to cheating in chess games. You agree to indemnify and hold harmless 
        Chess Predict, its officers, directors, employees, and agents from any claims, damages, losses, liabilities, 
        costs, and expenses (including reasonable attorneys' fees) arising from:
      </p>
      <ul>
        <li>Your use of the Service in violation of these Terms, particularly regarding prohibited uses for cheating.</li>
        <li>Your violation of any third-party rights, including chess platforms' terms of service or tournament rules.</li>
        <li>Any actions taken by chess platforms, tournament organizers, or other entities against you for misuse of our Service.</li>
        <li>Your use of or inability to use the Service.</li>
        <li>Any unauthorized access to or use of our servers and/or any personal information stored therein.</li>
      </ul>

      <h2>8. Disclaimer of Warranties</h2>
      <p>
        The Service is provided "as is" and "as available" without warranties of any kind, either express or implied. We
        do not guarantee that the Service will be uninterrupted or error-free.
      </p>

      <h2>9. Termination</h2>
      <p>
        We reserve the right to terminate or suspend your account and access to the Service at our sole discretion,
        without notice, for conduct that we believe violates these Terms or is harmful to other users of the Service,
        us, or third parties, or for any other reason.
      </p>

      <h2>10. Changes to the Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the new
        Terms on the Service. Your continued use of the Service after the effective date of the revised Terms
        constitutes your acceptance of the terms.
      </p>

      <h2>11. Governing Law</h2>
      <p>
        These Terms shall be governed by and construed in accordance with the laws of the United States, without regard
        to its conflict of law principles.
      </p>

      <h2>12. Contact Information</h2>
      <p>If you have any questions about these Terms, please contact us at support@chesspredict.com.</p>
    </div>
  )
}

export default TermsOfService

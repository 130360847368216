import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import './Checkout.css'

const tiers = [
  { name: 'Pawn (Basic)', price: 0.99, bestMoves: 20, hints: 30, analyses: 10, savings: 2.75 },
  { name: 'Rook (Standard)', price: 4.99, bestMoves: 100, hints: 150, analyses: 50, savings: 13.75 },
  { name: 'Queen (Premium)', price: 9.99, bestMoves: 200, hints: 300, analyses: 100, savings: 27.50 }
]

const CheckoutForm = ({ tier, currentTier, onTierChange }) => {
  const stripe = useStripe()
  const elements = useElements()
  const navigate = useNavigate()
  const [isProcessing, setIsProcessing] = useState(false)

  // Convert tier names to match backend format
  const getTierLevel = (tierName) => {
    const tierMap = {
      'Pawn (Basic)': 'basic',
      'Rook (Standard)': 'standard',
      'Queen (Premium)': 'premium'
    }
    return tierMap[tierName] || tierName.toLowerCase()
  }

  const isCurrentTier = getTierLevel(tier) === currentTier?.toLowerCase()

  const handleSubmit = async event => {
    event.preventDefault()
    if (isCurrentTier || isProcessing) return

    setIsProcessing(true)
    try {
      // Get selected tier details
      const selectedTier = tiers.find(t => t.name === tier)
      if (!selectedTier) return

      const token = localStorage.getItem('token')

      if (selectedTier.price === 0.0) {
        // If the tier is free, directly call the backend without Stripe payment
        const response = await axios.post(
          '/api/checkout',
          { token: null, tier },
          { headers: { 'x-access-token': token } }
        )
        if (response.data.success) {
          alert('Subscribed successfully to the free tier')
          navigate(`/upload?token=${encodeURIComponent(token)}`)
        } else {
          alert('Subscription failed: ' + response.data.error)
        }
      } else {
        // Proceed with Stripe payment if tier has a price > 0
        if (!stripe || !elements) {
          return
        }

        const cardElement = elements.getElement(CardElement)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement
        })

        if (error) {
          console.error(error)
          return
        }

        const response = await axios.post(
          '/api/checkout',
          { token: paymentMethod.id, tier },
          { headers: { 'x-access-token': token } }
        )
        if (response.data.success) {
          alert('Subscribed successfully')
          navigate(`/upload?token=${encodeURIComponent(token)}`)
        } else {
          alert('Payment failed: ' + response.data.error)
        }
      }
    } catch (error) {
      console.error('Payment Error:', error)
      alert('Payment failed: ' + (error.response?.data?.error || error.message))
    } finally {
      setIsProcessing(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className="checkout-form">
      <div className="tier-selector">
        <label htmlFor="tier-select">Select Tier:</label>
        <select 
          id="tier-select" 
          value={tier} 
          onChange={e => onTierChange(e.target.value)}
          disabled={isProcessing}
        >
          {tiers.map(t => (
            <option key={t.name} value={t.name}>
              {t.name} - ${t.price.toFixed(2)}/month
            </option>
          ))}
        </select>
      </div>

      {tiers.find(t => t.name === tier)?.price > 0 && <CardElement disabled={isProcessing} />}

      <button 
        type="submit" 
        disabled={!stripe || isCurrentTier || isProcessing || tiers.find(t => t.name === tier)?.price === 0}
      >
        {isCurrentTier ? 'Already Subscribed to This Tier' : 
         isProcessing ? 'Processing Payment...' : 'Pay'}
      </button>
    </form>
  )
}

function TierCard({ tier, selected, onSelect }) {
  const savingsAmount = (tier.savings - tier.price).toFixed(2);
  const savingsPercentage = ((tier.savings - tier.price) / tier.savings * 100).toFixed(0);
  
  return (
    <div 
      className={`tier-card ${selected ? 'selected' : ''}`}
      onClick={() => onSelect(tier)}
    >
      <div className="tier-header">{tier.name}</div>
      <div className="tier-price">${tier.price}<span className="per-month">/month</span></div>
      <ul className="tier-features">
        <li>{tier.bestMoves} Best Moves</li>
        <li>{tier.hints} Hints</li>
        <li>{tier.analyses} Analyses</li>
        <li className="savings-detail">
          <span className="original-price">Value: ${tier.savings}</span>
        </li>
      </ul>
      <span className="savings-tag">Save ${savingsAmount} ({savingsPercentage}%)</span>
    </div>
  );
}

const Checkout = () => {
  const [searchParams] = useSearchParams()
  const [tier, setTier] = useState('')
  const [currentTier, setCurrentTier] = useState('')
  const [stripePromise, setStripePromise] = useState(null)

  useEffect(() => {
    const fetchStripePublicKey = async () => {
      try {
        let token = localStorage.getItem('token')

        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/get-stripe-public-key', {
          headers: {
            'x-access-token': token
          }
        })
        const stripePublicKey = response.data.publicKey
        setStripePromise(loadStripe(stripePublicKey))
      } catch (error) {
        console.error('Error fetching Stripe public key:', error)
      }
    }

    fetchStripePublicKey()

    const selectedTier = searchParams.get('tier')
    setTier(selectedTier || 'Pawn (Basic)')

    const fetchCurrentTier = async () => {
      try {
        let token = localStorage.getItem('token')
        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/user-tier', {
          headers: {
            'x-access-token': token
          }
        })
        const dbTier = response.data.tier
        console.log('Tier from database:', dbTier)
        setCurrentTier(dbTier)
      } catch (error) {
        console.error('Error fetching user tier:', error)
      }
    }
    fetchCurrentTier()
  }, [searchParams])

  const handleTierChange = newTier => {
    setTier(newTier)
  }

  return (
    <div className="checkout-page">
      <h1>Choose Your Plan</h1>
      {currentTier && <p className="current-tier">Current Plan: {currentTier}</p>}
      
      <div className="tier-cards-container">
        {tiers.map((tierOption) => (
          <TierCard
            key={tierOption.name}
            tier={tierOption}
            selected={tier === tierOption.name}
            onSelect={(t) => handleTierChange(t.name)}
          />
        ))}
      </div>

      {stripePromise && (
        <div className="payment-section">
          <Elements stripe={stripePromise}>
            <CheckoutForm tier={tier} currentTier={currentTier} onTierChange={handleTierChange} />
          </Elements>
        </div>
      )}
    </div>
  )
}

export default Checkout

import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { loadStripe } from '@stripe/stripe-js'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import axios from 'axios'
import './PurchaseCredits.css'

// Separate component for the Stripe-enabled form
const StripeForm = ({ totalPrice, disabled, onSubmit, isProcessing }) => {
  // Convert totalPrice to number and handle any invalid values
  const formattedPrice = Number(totalPrice) || 0;

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding: '10px 12px',
        backgroundColor: 'white',
        ':-webkit-autofill': {
          color: '#32325d',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    disabled: isProcessing,
  };

  return (
    <div className="stripe-form">
      <CardElement options={cardElementOptions} />
      <button 
        type="button"
        onClick={onSubmit}
        disabled={disabled}
      >
        {isProcessing ? 'Processing Payment...' : `Pay $${formattedPrice.toFixed(2)}`}
      </button>
    </div>
  )
}

const CreditInput = ({ label, price, value, onChange, max = 1000, disabled }) => {
  return (
    <div className="credit-item">
      <div className="credit-header">
        <span className="credit-label">{label}</span>
        <span className="credit-price">${price} each</span>
      </div>
      <div className="credit-controls">
        <div className="credit-input">
          <input
            type="number"
            value={value}
            onChange={(e) => onChange(Math.min(Math.max(0, parseInt(e.target.value) || 0), max))}
            min="0"
            max={max}
            disabled={disabled}
          />
          <span>credits</span>
        </div>
        <input
          type="range"
          className="credit-slider"
          min="0"
          max={max}
          value={value}
          onChange={(e) => onChange(parseInt(e.target.value))}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

const PurchaseCreditsForm = () => {
  const navigate = useNavigate()
  const [credits, setCredits] = useState({
    bestMoves: 0,
    hints: 0,
    analyses: 0
  })
  const [total, setTotal] = useState(0)
  const [error, setError] = useState('')
  const [isProcessing, setIsProcessing] = useState(false)
  const stripe = useStripe()
  const elements = useElements()

  const prices = {
    bestMoves: 0.05,
    hints: 0.025,
    analyses: 0.10
  }

  const handleInputChange = (type, value) => {
    const newValue = Math.max(0, parseInt(value) || 0)
    const newCredits = { ...credits, [type]: newValue }
    setCredits(newCredits)
    
    const newTotal = (
      newCredits.bestMoves * prices.bestMoves +
      newCredits.hints * prices.hints +
      newCredits.analyses * prices.analyses
    ).toFixed(2)
    setTotal(newTotal)
  }

  const handleStripeSubmit = async (event) => {
    event.preventDefault()
    
    if (!stripe || !elements || isProcessing) {
      return
    }

    setIsProcessing(true)
    setError('')

    try {
      const cardElement = elements.getElement(CardElement)
      
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      })

      if (error) {
        console.error("Error creating payment method:", error)
        setError(error.message)
        setIsProcessing(false)
        return
      }

      const token = localStorage.getItem('token')
      
      const response = await axios.post(
        '/api/purchase-credits',
        {
          best_moves_quantity: credits.bestMoves,
          hints_quantity: credits.hints,
          analyses_quantity: credits.analyses,
          payment_method_id: paymentMethod.id
        },
        {
          headers: { 'x-access-token': token }
        }
      )

      if (response.data.message === 'Credits purchased successfully') {
        alert('Credits purchased successfully!')
        navigate('/my-account')
      } else {
        throw new Error('Unexpected response from server')
      }
    } catch (error) {
      console.error("Error:", error)
      setError(error.response?.data?.error || 'Failed to purchase credits')
      setIsProcessing(false)
    }
  }

  return (
    <form className="purchase-credits-form" onSubmit={(e) => e.preventDefault()}>
      <h1>Purchase Credits</h1>
      
      <CreditInput
        label="Best Moves"
        price={prices.bestMoves}
        value={credits.bestMoves}
        onChange={(value) => handleInputChange('bestMoves', value)}
        disabled={isProcessing}
      />
      
      <CreditInput
        label="Hints"
        price={prices.hints}
        value={credits.hints}
        onChange={(value) => handleInputChange('hints', value)}
        disabled={isProcessing}
      />
      
      <CreditInput
        label="Analyses"
        price={prices.analyses}
        value={credits.analyses}
        onChange={(value) => handleInputChange('analyses', value)}
        disabled={isProcessing}
      />

      <div className="total-section">
        <h2>Total</h2>
        <div className="total-price">${total}</div>
      </div>

      <StripeForm 
        totalPrice={total} 
        disabled={total === 0 || isProcessing}
        onSubmit={handleStripeSubmit}
        isProcessing={isProcessing}
      />

      {error && <p className="error">{error}</p>}
    </form>
  )
}

const PurchaseCredits = () => {
  const [stripePromise, setStripePromise] = useState(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const fetchStripePublicKey = async () => {
      try {
        let token = localStorage.getItem('token')
        const extensionToken = searchParams.get('extension_token')

        if (extensionToken) {
          token = extensionToken
          localStorage.setItem('token', extensionToken)
        }

        if (!token) {
          console.error('No token found')
          return
        }

        const response = await axios.get('/api/get-stripe-public-key', {
          headers: { 'x-access-token': token }
        })
        const stripePublicKey = response.data.publicKey
        setStripePromise(loadStripe(stripePublicKey))
      } catch (error) {
        console.error('Error fetching Stripe public key:', error)
      }
    }

    fetchStripePublicKey()
  }, [searchParams])

  return (
    <div className="purchase-credits-page">
      {stripePromise ? (
        <Elements stripe={stripePromise}>
          <PurchaseCreditsForm />
        </Elements>
      ) : (
        <div>Loading payment system...</div>
      )}
    </div>
  )
}

export default PurchaseCredits
